import { config } from "src/config/config";
import { AjaxStatus } from "src/service/api_types";
import {
  getAllPublicAssetsAPI,
  getSaleAssetsByBrandAPI,
  getRentalAssetsByBrandAPI,
  getTradingAssetsAPI,
  getTradableListedAssetsAPI,
  getGroupsByBrandAPI,
  getNewReleaseAssetsAPI,
  getTopSalesAssetsAPI,
  getMostPopularAssetsAPI,
  getAssetsByCreatorAPI,
  getPublicAssetDetailsAPI,
  getSpecificAssetDetailsAPI,
} from "src/service/public_asset_api";

export const PUBLIC_ASSET_ACTIONS = {
  INIT_ALL_PUBLIC_ASSETS: "INIT_ALL_PUBLIC_ASSETS",
  START_ALL_PUBLIC_ASSETS: "START_ALL_PUBLIC_ASSETS",
  GET_ALL_PUBLIC_ASSETS: "GET_ALL_PUBLIC_ASSETS",
  INIT_SALE_ASSETS_BY_BRAND: "INIT_SALE_ASSETS_BY_BRAND",
  START_SALE_ASSETS_BY_BRAND: "START_SALE_ASSETS_BY_BRAND",
  GET_SALE_ASSETS_BY_BRAND: "GET_SALE_ASSETS_BY_BRAND",
  INIT_RENTAL_ASSETS_BY_BRAND: "INIT_RENTAL_ASSETS_BY_BRAND",
  START_RENTAL_ASSETS_BY_BRAND: "START_RENTAL_ASSETS_BY_BRAND",
  GET_RENTAL_ASSETS_BY_BRAND: "GET_RENTAL_ASSETS_BY_BRAND",
  INIT_TRADING_ASSETS: "INIT_TRADING_ASSETS",
  START_TRADING_ASSETS: "START_TRADING_ASSETS",
  GET_TRADING_ASSETS: "GET_TRADING_ASSETS",
  INIT_TRADABLE_LISTED_ASSETS: "INIT_TRADABLE_LISTED_ASSETS",
  START_TRADABLE_LISTED_ASSETS: "START_TRADABLE_LISTED_ASSETS",
  GET_TRADABLE_LISTED_ASSETS: "GET_TRADABLE_LISTED_ASSETS",
  INIT_GROUPS_BY_BRAND: "INIT_GROUPS_BY_BRAND",
  START_GROUPS_BY_BRAND: "START_GROUPS_BY_BRAND",
  GET_GROUPS_BY_BRAND: "GET_GROUPS_BY_BRAND",
  INIT_NEW_RELEASE_ASSETS: "INIT_NEW_RELEASE_ASSETS",
  START_NEW_RELEASE_ASSETS: "START_NEW_RELEASE_ASSETS",
  GET_NEW_RELEASE_ASSETS: "GET_NEW_RELEASE_ASSETS",
  GET_TOP_SALES_ASSETS: "GET_TOP_SALES_ASSETS",
  INIT_TOP_SALES_ASSETS: "INIT_TOP_SALES_ASSETS",
  START_TOP_SALES_ASSETS: "START_TOP_SALES_ASSETS",
  GET_MOST_POPULAR_ASSETS: "GET_MOST_POPULAR_ASSETS",
  INIT_MOST_POPULAR_ASSETS: "INIT_MOST_POPULAR_ASSETS",
  START_MOST_POPULAR_ASSETS: "START_MOST_POPULAR_ASSETS",
  GET_ASSETS_BY_CREATOR: "GET_ASSETS_BY_CREATOR",
  INIT_ASSETS_BY_CREATOR: "INIT_ASSETS_BY_CREATOR",
  START_ASSETS_BY_CREATOR: "START_ASSETS_BY_CREATOR",
  GET_PUBLIC_ASSETS_DETAILS: "GET_PUBLIC_ASSETS_DETAILS",
  INIT_SPECIFIC_ASSETS_DETAILS: "INIT_SPECIFIC_ASSETS_DETAILS",
  START_SPECIFIC_ASSETS_DETAILS: "START_SPECIFIC_ASSETS_DETAILS",
  GET_SPECIFIC_ASSETS_DETAILS: "GET_SPECIFIC_ASSETS_DETAILS",
};

export const initGetAllPublicAssets = () => ({
  type: PUBLIC_ASSET_ACTIONS.INIT_ALL_PUBLIC_ASSETS,
});

export const startGetAllPublicAssets = () => ({
  type: PUBLIC_ASSET_ACTIONS.START_ALL_PUBLIC_ASSETS,
});

export const getAllPubicAssets = (data: any) => ({
  type: PUBLIC_ASSET_ACTIONS.GET_ALL_PUBLIC_ASSETS,
  data,
});

export const initGetSaleAssetsByBrand = () => ({
  type: PUBLIC_ASSET_ACTIONS.INIT_SALE_ASSETS_BY_BRAND,
});

export const startGetSaleAssetsByBrand = () => ({
  type: PUBLIC_ASSET_ACTIONS.START_SALE_ASSETS_BY_BRAND,
});

export const getSaleAssetsByBrand = (data: any) => ({
  type: PUBLIC_ASSET_ACTIONS.GET_SALE_ASSETS_BY_BRAND,
  data,
});

export const initGetRentalAssetsByBrand = () => ({
  type: PUBLIC_ASSET_ACTIONS.INIT_RENTAL_ASSETS_BY_BRAND,
});

export const startGetRentalAssetsByBrand = () => ({
  type: PUBLIC_ASSET_ACTIONS.START_RENTAL_ASSETS_BY_BRAND,
});

export const getRentalAssetsByBrand = (data: any) => ({
  type: PUBLIC_ASSET_ACTIONS.GET_RENTAL_ASSETS_BY_BRAND,
  data,
});

export const initGetTradingAssets = () => ({
  type: PUBLIC_ASSET_ACTIONS.INIT_TRADING_ASSETS,
});

export const startGetTradingAssets = () => ({
  type: PUBLIC_ASSET_ACTIONS.START_TRADING_ASSETS,
});

export const getTradingAssets = (data: any) => ({
  type: PUBLIC_ASSET_ACTIONS.GET_TRADING_ASSETS,
  data,
});

export const initGetTradableListedAssets = () => ({
  type: PUBLIC_ASSET_ACTIONS.INIT_TRADABLE_LISTED_ASSETS,
});

export const startGetTradableListedAssets = () => ({
  type: PUBLIC_ASSET_ACTIONS.START_TRADABLE_LISTED_ASSETS,
});

export const getTradableListedAssets = (data: any) => ({
  type: PUBLIC_ASSET_ACTIONS.GET_TRADABLE_LISTED_ASSETS,
  data,
});

export const initGetGroupsByBrand = () => ({
  type: PUBLIC_ASSET_ACTIONS.INIT_GROUPS_BY_BRAND,
});

export const startGetGroupsByBrand = () => ({
  type: PUBLIC_ASSET_ACTIONS.START_GROUPS_BY_BRAND,
});

export const getGroupsByBrand = (data: any) => ({
  type: PUBLIC_ASSET_ACTIONS.GET_GROUPS_BY_BRAND,
  data,
});

export const initGetNewReleaseAssets = () => ({
  type: PUBLIC_ASSET_ACTIONS.INIT_NEW_RELEASE_ASSETS,
});

export const startGetNewReleaseAssets = () => ({
  type: PUBLIC_ASSET_ACTIONS.START_NEW_RELEASE_ASSETS,
});

export const getNewReleaseAssets = (data: any) => ({
  type: PUBLIC_ASSET_ACTIONS.GET_NEW_RELEASE_ASSETS,
  data,
});

export const getTopSalesAssets = (data: any) => ({
  type: PUBLIC_ASSET_ACTIONS.GET_TOP_SALES_ASSETS,
  data,
});

export const initTopSalesAssets = () => ({
  type: PUBLIC_ASSET_ACTIONS.INIT_TOP_SALES_ASSETS,
});

export const startTopSalesAssets = () => ({
  type: PUBLIC_ASSET_ACTIONS.START_TOP_SALES_ASSETS,
});

export const getMostPopularAssets = (data: any) => ({
  type: PUBLIC_ASSET_ACTIONS.GET_MOST_POPULAR_ASSETS,
  data,
});

export const initMostPopularAssets = () => ({
  type: PUBLIC_ASSET_ACTIONS.INIT_MOST_POPULAR_ASSETS,
});

export const startMostPopularAssets = () => ({
  type: PUBLIC_ASSET_ACTIONS.START_MOST_POPULAR_ASSETS,
});

export const getAssetsByCreator = (data: any) => ({
  type: PUBLIC_ASSET_ACTIONS.GET_ASSETS_BY_CREATOR,
  data,
});

export const initAssetsByCreator = () => ({
  type: PUBLIC_ASSET_ACTIONS.INIT_ASSETS_BY_CREATOR,
});

export const startAssetsByCreator = () => ({
  type: PUBLIC_ASSET_ACTIONS.START_ASSETS_BY_CREATOR,
});

export const getPublicAssetDetails = (data: any) => ({
  type: PUBLIC_ASSET_ACTIONS.GET_PUBLIC_ASSETS_DETAILS,
  data,
});

export const initSpecificAssetDetails = () => ({
  type: PUBLIC_ASSET_ACTIONS.INIT_SPECIFIC_ASSETS_DETAILS,
});

export const startSpecificAssetDetails = () => ({
  type: PUBLIC_ASSET_ACTIONS.START_SPECIFIC_ASSETS_DETAILS,
});

export const getSpecificAssetDetails = (data: any) => ({
  type: PUBLIC_ASSET_ACTIONS.GET_SPECIFIC_ASSETS_DETAILS,
  data,
});

export const getAllPublicAssetsDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      assetType: params.assetType,
      mediaType: params.mediaType,
      bizCond: params.bizCond,
      sortBy: params.sortBy,
    };

    dispatch(startGetAllPublicAssets());

    return getAllPublicAssetsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received all public assets: ", data);
        }
        let assetsData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getAllPubicAssets(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting all public assets");
        }
        dispatch(
          getAllPubicAssets({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getSaleAssetsByBrandDispatch =
  (params: any) => (dispatch: Function) => {
    const options = {
      accessToken: params.accessToken,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      sortBy: params.sortBy,
      account: params.account,
    };

    dispatch(startGetSaleAssetsByBrand());

    return getSaleAssetsByBrandAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets: ", data.sale_assets);
        }
        const assetsData = {
          pageNumber: params.pageNumber,
          response: data.sale_assets,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getSaleAssetsByBrand(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting sale assets in the brand");
        }
        dispatch(
          getSaleAssetsByBrand({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getRentalAssetsByBrandDispatch =
  (params: any) => (dispatch: Function) => {
    const options = {
      accessToken: params.accessToken,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      sortBy: params.sortBy,
      account: params.account,
    };

    dispatch(startGetRentalAssetsByBrand());

    return getRentalAssetsByBrandAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets: ", data.rental_assets);
        }
        const assetsData = {
          pageNumber: params.pageNumber,
          response: data.rental_assets,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getRentalAssetsByBrand(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting rental assets in the brand");
        }
        dispatch(
          getRentalAssetsByBrand({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getTradingAssetsDispatch =
  (params: any) => (dispatch: Function) => {
    const options = {
      accessToken: params.accessToken,
      tradingAssetTokenIds: params.trading_asset_token_ids,
      // pageNumber: params.pageNumber,
      // pageSize: params.pageSize,
      // sortBy: params.sortBy,
      account: params.account,
    };
    console.log("getTradingAssetsDispatch: " + options.tradingAssetTokenIds);

    dispatch(startGetTradingAssets());

    return getTradingAssetsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets: ", data);
        }
        const assetsData = {
          // pageNumber: params.pageNumber,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getTradingAssets(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting trading assets");
        }
        dispatch(
          getTradingAssets({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getTradableListedAssetsDispatch =
  (params: any) => (dispatch: Function) => {
    const options = {
      accessToken: params.accessToken,
      tradingAssetTokenIds: params.trading_asset_token_ids,
      // pageNumber: params.pageNumber,
      // pageSize: params.pageSize,
      // sortBy: params.sortBy,
      account: params.account,
    };

    dispatch(startGetTradableListedAssets());

    return getTradableListedAssetsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets: ", data);
        }
        const assetsData = {
          // pageNumber: params.pageNumber,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getTradableListedAssets(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting traadble assets");
        }
        dispatch(
          getTradableListedAssets({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getGroupsByBrandDispatch =
  (params: any) => (dispatch: Function) => {
    const options = {
      accessToken: params.accessToken,
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      sortBy: params.sortBy,
      account: params.account,
    };

    dispatch(startGetGroupsByBrand());

    return getGroupsByBrandAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets: ", data);
        }
        const assetsData = {
          pageNumber: params.pageNumber,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getGroupsByBrand(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting group assets in the brand");
        }
        dispatch(
          getGroupsByBrand({
            response: [],
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getNewReleaseAssetsDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      assetType: params.assetType,
      mediaType: params.mediaType,
      bizCond: params.bizCond,
      sortDateOnAfter: params.sortDateOnAfter,
      sortBy: params.sortBy,
    };

    dispatch(startGetNewReleaseAssets());

    return getNewReleaseAssetsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received new release assets: ", data);
        }
        let assetsData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getNewReleaseAssets(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting new release assets");
        }
        dispatch(
          getNewReleaseAssets({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getTopSalesAssetsDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      assetType: params.assetType,
      mediaType: params.mediaType,
      bizCond: params.bizCond,
      sortBy: params.sortBy,
    };

    dispatch(startTopSalesAssets());

    return getTopSalesAssetsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received top sales assets: ", data);
        }
        let assetsData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getTopSalesAssets(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting top sales assets");
        }
        dispatch(
          getTopSalesAssets({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getMostPopularAssetsDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      assetType: params.assetType,
      mediaType: params.mediaType,
      bizCond: params.bizCond,
      sortBy: params.sortBy,
    };

    dispatch(startMostPopularAssets());

    return getMostPopularAssetsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received most popular assets: ", data);
        }
        let assetsData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getMostPopularAssets(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting most popular assets");
        }
        dispatch(
          getMostPopularAssets({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getAssetsByCreatorDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      pageNumber: params.pageNumber,
      pageSize: params.pageSize,
      creatorUserID: params.creatorUserID,
      assetType: params.assetType,
      mediaType: params.mediaType,
      bizCond: params.bizCond,
      sortBy: params.sortBy,
    };

    dispatch(startAssetsByCreator());

    return getAssetsByCreatorAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received assets by creator: ", data);
        }
        let assetsData = {
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          response: data,
          ajaxStat: AjaxStatus.DONE,
        };
        dispatch(getAssetsByCreator(assetsData));
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting assets by creator");
        }
        dispatch(
          getAssetsByCreator({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getPublicAssetDetailsDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      assetTokenID: params.assetTokenID,
    };
    return getPublicAssetDetailsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received public asset details: ", data);
        }
        dispatch(
          getPublicAssetDetails({
            ajaxStat: AjaxStatus.DONE,
            response: data,
          })
        );
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting public asset details");
        }
        dispatch(
          getPublicAssetDetails({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };

export const getSpecificAssetDetailsDispatch =
  (params: any) => (dispatch: Function) => {
    let options = {
      accessToken: params.accessToken,
      assetKid: params.assetKid,
      userCurrency: params.userCurrency,
    };

    dispatch(startSpecificAssetDetails());

    return getSpecificAssetDetailsAPI(options)
      .done((data, status, xhr) => {
        if (config.APP_DEBUG) {
          console.log("Headers: ", xhr.getAllResponseHeaders());
          console.log("Received public asset details: ", data);
        }
        dispatch(
          getSpecificAssetDetails({
            ajaxStat: AjaxStatus.DONE,
            response: data,
          })
        );
      })
      .fail((err) => {
        if (config.APP_DEBUG) {
          console.error("Error in getting public asset details");
        }
        dispatch(
          getSpecificAssetDetails({
            response: {},
            ajaxStat: AjaxStatus.FAILED,
          })
        );
      });
  };
