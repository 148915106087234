import { Suspense, lazy } from "react";
import type { RouteObject } from "react-router-dom";
import MyAssetsRedirect from "src/components/account/my_assets";
import MyChannelsRedirect from "src/components/account/my_channels";
import CollectedContentsBrandsDetailPage from "src/components/collected_contents/brands/detail/page";
import CouponsOccupationsNewPage from "src/components/coupons/occupations/new/page";
import { AssetCategory } from "src/service/api_types";

const Main = lazy(() => import("src/components/main"));
const Home = lazy(() => import("src/components/home"));
const Marketplace = lazy(() => import("src/components/marketplace"));
const CreateAsset = lazy(() => import("src/components/asset/create"));
const NewRelease = lazy(() => import("src/components/newdrops"));
const Rankings = lazy(() => import("src/components/rankings"));
const LoginPage = lazy(() => import("src/components/login/page"));
const AssetDetails = lazy(() => import("src/components/asset/details"));
const LoadPrivateAssetDetails = lazy(
  () => import("src/components/asset/details/load_private")
);
const SaleAssetDetails = lazy(
  () => import("src/components/asset/details/sale")
);
const RentalAssetDetails = lazy(
  () => import("src/components/asset/details/rental")
);
const Account = lazy(() => import("src/components/account"));
const CreatedAssetPage = lazy(
  () => import("src/components/asset/created/page")
);
const Funds = lazy(() => import("src/components/funds"));
const Notifications = lazy(() => import("src/components/notifications"));
const ExpenseDetails = lazy(() => import("src/components/funds/detail"));
const Owners = lazy(() => import("src/components/owners"));
const EditAssetPage = lazy(() => import("src/components/asset/edit/page"));
const DistributeAsset = lazy(() => import("src/components/asset/distribute"));
const FreeRentalListing = lazy(
  () => import("src/components/asset/free_rental_listing")
);
const TradeListing = lazy(() => import("src/components/asset/trade_listing"));
const TradeOffer = lazy(() => import("src/components/asset/trade_offer"));
const Setting = lazy(() => import("src/components/setting"));
const Payment = lazy(() => import("src/components/payment"));
const Purchase = lazy(() => import("src/components/payment/purchase"));
const Rental = lazy(() => import("src/components/payment/rental"));
const PurchaseSuccess = lazy(
  () => import("src/components/payment/purchase/success")
);
const RentalSuccess = lazy(
  () => import("src/components/payment/rental/success")
);
const PurchaseCancel = lazy(
  () => import("src/components/payment/purchase/cancel")
);
const RentalCancel = lazy(() => import("src/components/payment/rental/cancel"));
const RentSuccess = lazy(() => import("src/components/payment/rental/success"));
const Error = lazy(() => import("src/components/error"));
const CreateBrand = lazy(() => import("src/components/brand/create"));
const BrandDetailsRedirect = lazy(
  () => import("src/components/brand/details/brand_details_redirect")
);
const BrandDetails = lazy(() => import("src/components/brand/details"));
const EditBrand = lazy(() => import("src/components/brand/edit"));
const OwnedSerials = lazy(
  () => import("src/components/asset/details/serials/index")
);
const TradeListings = lazy(
  () => import("src/components/asset/details/serials/trade_listings")
);
const CreatedSerials = lazy(
  () => import("src/components/asset/created/serials/index")
);
const TradeOfferHistories = lazy(
  () => import("src/components/histories/trade_offer/page")
);
const PointDetails = lazy(() => import("src/components/account/point/detail"));
const RegisterBank = lazy(() => import("src/components/bank/register"));
// const GroupDetails = lazy(() => import("src/components/group/detail"));
const LoadGroupDetails = lazy(() => import("src/components/group/detail/load"));
const Payout = lazy(() => import("src/components/payout"));
const Interview = lazy(() => import("src/components/pages/interview"));
const About = lazy(() => import("src/components/pages/about"));
const Company = lazy(() => import("src/components/pages/company"));
const Dvt = lazy(() => import("src/components/pages/dvt"));
const PersonalInformation = lazy(
  () => import("src/components/pages/personal_information")
);
const AirPortAnifes = lazy(() => import("src/components/pages/airport_anifes"));
const CreateGroup = lazy(() => import("src/components/group/create"));
const EditGroup = lazy(() => import("src/components/group/edit"));

const Admin = lazy(() => import("src/components/admin"));
const AdminPayout = lazy(() => import("src/components/payout/admin"));
const AdminUsers = lazy(() => import("src/components/users/admin"));
const AdminAccountings = lazy(() => import("src/components/accountings/admin"));

const AugustMyHeaven = lazy(
  () => import("src/components/pages/august_my_heaven")
);

interface TRMRouteObject extends RouteObject {
  name: string;
}

const mainRoutes = [
  {
    name: "Main",
    path: "/",
    element: (
      <Suspense fallback={null}>
        <Main />
      </Suspense>
    ),
    children: [
      {
        name: "Home",
        path: "/",
        key: "home",
        index: true,
        show: false,
        element: (
          <Suspense fallback={null}>
            <Marketplace />
          </Suspense>
        ),
      },
      {
        path: "/personal_information",
        element: (
          <Suspense fallback={null}>
            <PersonalInformation></PersonalInformation>
          </Suspense>
        ),
      },

      // TODO: 以下コメントアウトについて、関連コードとともに削除する。
      // {
      //   name: "New Drops",
      //   path: "/newdrops",
      //   show: true,
      //   element: (
      //     <Suspense fallback={null}>
      //       <NewRelease />
      //     </Suspense>
      //   ),
      // },

      // TODO: 不要なら削除する。
      {
        name: "Marketplace",
        path: "/marketplace",
        key: "marketplace",
        show: true,
        element: (
          <Suspense fallback={null}>
            <Marketplace />
          </Suspense>
        ),
      },

      // TODO: 不要なら削除する。
      {
        name: "My Assets",
        path: "/my-assets/",
        key: "myAsset",
        show: true,
        element: (
          <Suspense fallback={null}>
            <MyAssetsRedirect></MyAssetsRedirect>
          </Suspense>
        ),
      },

      {
        key: "create",
        name: "Create",
        path: "/create",
        show: true,
        element: (
          <Suspense fallback={null}>
            <CreateAsset />
          </Suspense>
        ),
      },

      // TODO: 以下コメントアウトについて、関連コードとともに削除する。
      // {
      //   name: "Rankings",
      //   path: "/rankings",
      //   show: true,
      //   element: (<Suspense fallback={null}><Rankings /></Suspense>),
      // },
      // {
      //   name: "My Channels",
      //   path: "/",
      //   show: true,
      //   element: (<Suspense fallback={null}><MyChannelsRedirect></MyChannelsRedirect></Suspense>)
      // },

      {
        name: "BoughtAsset",
        path: "/asset",
        show: false,
        element: (
          <Suspense fallback={null}>
            <LoadPrivateAssetDetails assetCategory={AssetCategory.COLLECTED} />
          </Suspense>
        ),
      },
      {
        name: "RentedAsset",
        path: "/asset/rented",
        show: false,
        element: (
          <Suspense fallback={null}>
            <LoadPrivateAssetDetails assetCategory={AssetCategory.RENTED} />
          </Suspense>
        ),
      },
      {
        name: "PointDetails",
        key: "pointDetails",
        path: "/account/trading_histories",
        show: false,
        element: (
          <Suspense fallback={null}>
            <PointDetails></PointDetails>
          </Suspense>
        ),
      },
      {
        path: "/account/trade_offer_histories",
        show: false,
        element: (
          <Suspense fallback={null}>
            <TradeOfferHistories></TradeOfferHistories>
          </Suspense>
        ),
      },
      {
        key: "assetByKid",
        name: "Asset",
        path: "/asset/:kid",
        show: false,
        element: (
          <Suspense fallback={null}>
            <SaleAssetDetails />
          </Suspense>
        ),
      },
      {
        key: "RentalAssetByKid",
        name: "RentalAsset",
        path: "/asset/rental/:kid",
        show: false,
        element: (
          <Suspense fallback={null}>
            <RentalAssetDetails />
          </Suspense>
        ),
      },
      {
        name: "Account",
        key: "account",
        path: "/account/:type",
        show: false,
        element: (
          <Suspense fallback={null}>
            <Account></Account>
          </Suspense>
        ),
      },
      {
        name: "Own",
        path: "/asset/:kid/created",
        show: false,
        element: (
          <Suspense fallback={null}>
            <CreatedAssetPage></CreatedAssetPage>
          </Suspense>
        ),
      },
      {
        path: "/collected_contents/brands/:key",
        element: (
          <Suspense fallback={null}>
            <CollectedContentsBrandsDetailPage></CollectedContentsBrandsDetailPage>
          </Suspense>
        ),
      },
      {
        path: "/coupons/occupations/new",
        element: (
          <Suspense fallback={null}>
            <CouponsOccupationsNewPage />
          </Suspense>
        ),
      },

      // TODO: 以下コメントアウトについて、関連コードとともに削除する。
      // {
      //   name: "Funds",
      //   path: "/funds",
      //   show: false,
      //   element: (
      //     <Suspense fallback={null}>
      //       <Funds></Funds>
      //     </Suspense>
      //   ),
      // },
      // {
      //   name: "Funds",
      //   path: "/funds/detail",
      //   show: false,
      //   element: (
      //     <Suspense fallback={null}>
      //       <ExpenseDetails></ExpenseDetails>
      //     </Suspense>
      //   ),
      // },
      // {
      //   name: "Notifications",
      //   path: "/notifications",
      //   show: false,
      //   element: (
      //     <Suspense fallback={null}>
      //       <Notifications></Notifications>
      //     </Suspense>
      //   ),
      // },
      // {
      //   name: "Payment",
      //   path: "/payment",
      //   show: false,
      //   element: (
      //     <Suspense fallback={null}>
      //       <Payment></Payment>
      //     </Suspense>
      //   ),
      // },
      // {
      //   name: "Owners",
      //   path: "/owners/:type",
      //   show: false,
      //   element: (
      //     <Suspense fallback={null}>
      //       <Owners></Owners>
      //     </Suspense>
      //   ),
      // },

      {
        name: "Edit",
        path: "/asset/:kid/edit",
        show: false,
        element: (
          <Suspense fallback={null}>
            <EditAssetPage></EditAssetPage>
          </Suspense>
        ),
      },
      {
        name: "Distribute",
        path: "/asset/distribute",
        show: false,
        element: (
          <Suspense fallback={null}>
            <DistributeAsset></DistributeAsset>
          </Suspense>
        ),
      },
      {
        path: "/asset/free_rental_listing",
        element: (
          <Suspense fallback={null}>
            <FreeRentalListing />
          </Suspense>
        ),
      },
      {
        name: "TradeListing",
        path: "/asset/trade_listing",
        show: false,
        element: (
          <Suspense fallback={null}>
            <TradeListing></TradeListing>
          </Suspense>
        ),
      },
      {
        name: "TradeOffer",
        path: "/asset/:key/offer",
        show: false,
        element: (
          <Suspense fallback={null}>
            <TradeOffer></TradeOffer>
          </Suspense>
        ),
      },
      {
        name: "Setting",
        path: "/settings",
        show: false,
        element: (
          <Suspense fallback={null}>
            <Setting></Setting>
          </Suspense>
        ),
      },
      {
        name: "Purchase",
        path: "/payment/purchase/:kid",
        show: false,
        element: (
          <Suspense fallback={null}>
            <Purchase></Purchase>
          </Suspense>
        ),
      },
      {
        name: "Rental",
        path: "/payment/rental/:kid",
        show: false,
        element: (
          <Suspense fallback={null}>
            <Rental></Rental>
          </Suspense>
        ),
      },
      {
        name: "PurchaseSuccess",
        path: "/payment/purchase/success/:brandKey",
        show: false,
        element: (
          <Suspense fallback={null}>
            <PurchaseSuccess></PurchaseSuccess>
          </Suspense>
        ),
      },
      {
        name: "RentalSuccess",
        path: "/payment/rental/success/:brandKey",
        show: false,
        element: (
          <Suspense fallback={null}>
            <RentalSuccess></RentalSuccess>
          </Suspense>
        ),
      },
      {
        name: "PurchaseCancel",
        path: "/payment/purchase/cancel/:kid/:serialNumber",
        show: false,
        element: (
          <Suspense fallback={null}>
            <PurchaseCancel></PurchaseCancel>
          </Suspense>
        ),
      },
      {
        name: "RentalCancel",
        path: "/payment/rental/cancel/:kid/:serialNumber",
        show: false,
        element: (
          <Suspense fallback={null}>
            <RentalCancel></RentalCancel>
          </Suspense>
        ),
      },
      {
        name: "RentSuccess",
        path: "/payment/rent/success",
        show: false,
        element: (
          <Suspense fallback={null}>
            <RentSuccess></RentSuccess>
          </Suspense>
        ),
      },
      {
        name: "Error",
        path: "/error",
        show: false,
        element: (
          <Suspense fallback={null}>
            <Error></Error>
          </Suspense>
        ),
      },
      {
        name: "BrandDetails",
        path: "/brand/:account",
        show: false,
        element: (
          <Suspense fallback={null}>
            <BrandDetailsRedirect></BrandDetailsRedirect>
          </Suspense>
        ),
      },
      {
        name: "BrandSaleDetails",
        path: "/brand/:account/sale",
        show: false,
        element: (
          <Suspense fallback={null}>
            <BrandDetails></BrandDetails>
          </Suspense>
        ),
      },
      {
        name: "BrandsRentalDetails",
        path: "/brand/:account/rental",
        show: false,
        element: (
          <Suspense fallback={null}>
            <BrandDetails></BrandDetails>
          </Suspense>
        ),
      },
      {
        name: "BrandsGroupDetails",
        path: "/brand/:account/group",
        show: false,
        element: (
          <Suspense fallback={null}>
            <BrandDetails></BrandDetails>
          </Suspense>
        ),
      },
      {
        key: "createBrand",
        name: "Create Brand",
        path: "/brand/create",
        show: false,
        element: (
          <Suspense fallback={null}>
            <CreateBrand></CreateBrand>
          </Suspense>
        ),
      },
      {
        key: "editBrand",
        name: "Edit Brand",
        path: "/brand/edit",
        show: false,
        element: (
          <Suspense fallback={null}>
            <EditBrand></EditBrand>
          </Suspense>
        ),
      },
      {
        key: "createGroup",
        name: "Create Group",
        path: "/group/create",
        show: false,
        element: (
          <Suspense fallback={null}>
            <CreateGroup></CreateGroup>
          </Suspense>
        ),
      },
      {
        key: "editGroup",
        name: "Edit Group",
        path: "/group/edit",
        show: false,
        element: (
          <Suspense fallback={null}>
            <EditGroup></EditGroup>
          </Suspense>
        ),
      },
      {
        key: "OwnedSerials",
        name: "OwnedSerials",
        path: "/asset/:kid/serials",
        show: false,
        element: (
          <Suspense fallback={null}>
            <OwnedSerials></OwnedSerials>
          </Suspense>
        ),
      },
      {
        key: "CreatedSerials",
        name: "CreatedSerials",
        path: "/asset/created/:kid/serials",
        show: false,
        element: (
          <Suspense fallback={null}>
            <CreatedSerials></CreatedSerials>
          </Suspense>
        ),
      },
      {
        path: "/asset/:kid/trade_listings",
        element: (
          <Suspense fallback={null}>
            <TradeListings></TradeListings>
          </Suspense>
        ),
      },
      // {
      //   name: "GroupDetails",
      //   path: "/group/:encrypted_id",
      //   show: false,
      //   element: (
      //     <Suspense fallback={null}>
      //       <GroupDetails></GroupDetails>
      //     </Suspense>
      //   ),
      // },
      {
        name: "LoadGroupDetails",
        path: "/group/:encrypted_id",
        show: false,
        element: (
          <Suspense fallback={null}>
            <LoadGroupDetails></LoadGroupDetails>
          </Suspense>
        ),
      },
      {
        name: "RegisterBank",
        path: "/bank/register",
        show: false,
        element: (
          <Suspense fallback={null}>
            <RegisterBank></RegisterBank>
          </Suspense>
        ),
      },
      {
        name: "Payout",
        path: "/payout",
        show: false,
        element: (
          <Suspense fallback={null}>
            <Payout></Payout>
          </Suspense>
        ),
      },
      {
        key: "Interview",
        name: "Interview",
        path: "/interview",
        show: false,
        element: (
          <Suspense fallback={null}>
            <Interview></Interview>
          </Suspense>
        ),
      },
      {
        key: "About",
        name: "About",
        path: "/about",
        show: false,
        element: (
          <Suspense fallback={null}>
            <About></About>
          </Suspense>
        ),
      },
      {
        key: "Company",
        name: "Company",
        path: "/company",
        show: false,
        element: (
          <Suspense fallback={null}>
            <Company></Company>
          </Suspense>
        ),
      },
      {
        key: "Dvt",
        name: "Dvt",
        path: "/dvt",
        show: false,
        element: (
          <Suspense fallback={null}>
            <Dvt></Dvt>
          </Suspense>
        ),
      },
      {
        path: "/airport-anifes",
        element: (
          <Suspense fallback={null}>
            <AirPortAnifes></AirPortAnifes>
          </Suspense>
        ),
      },
    ],
  },
  {
    name: "Admin",
    path: "/admin",
    element: (
      <Suspense fallback={null}>
        <Admin />
      </Suspense>
    ),
    children: [
      {
        name: "Payout",
        path: "/admin/payout",
        show: false,
        element: (
          <Suspense fallback={null}>
            <AdminPayout></AdminPayout>
          </Suspense>
        ),
      },
      {
        name: "User",
        path: "/admin/users",
        show: false,
        element: (
          <Suspense fallback={null}>
            <AdminUsers></AdminUsers>
          </Suspense>
        ),
      },
      {
        name: "Accounting",
        path: "/admin/accountings",
        show: false,
        element: (
          <Suspense fallback={null}>
            <AdminAccountings></AdminAccountings>
          </Suspense>
        ),
      },
    ],
  },
  {
    key: "AugustMyHeaven",
    name: "AugustMyHeaven",
    path: "/august_my_heaven",
    show: false,
    element: (
      <Suspense fallback={null}>
        <AugustMyHeaven></AugustMyHeaven>
      </Suspense>
    ),
  },
  {
    name: "Login",
    path: "/login",
    element: (
      <Suspense fallback={null}>
        <LoginPage />
      </Suspense>
    ),
  },
];

const routes: TRMRouteObject[] = [...mainRoutes];

export { routes, mainRoutes };
